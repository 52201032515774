export var STORAGE_KEYS = {
  searchHistory: "search-history",
  tailoredSearchTags: "tailored-search-tags"
};
export var EXCEPTIONAL_CATEGORIES = {
  flasOffers: {
    textId: "offerte-flash"
  },
  groupAds: {
    textId: "annunci-di-gruppo"
  },
  others: {
    textId: "altri"
  }
};
export var FILTER_PATHS = {
  root: "/annunci",
  country: "/vendita"
};
export var DB_DEFAULT_LOCALE = "en";
export var ADSENSE_ENABLE_TEST_ADS = false; //ca-pub-1078370316201160

export var ADSENSE_PUB_ID = "ca-pub-1078370316201160"; //6468672360

export var ADSENSE_BANNER_TOP = "6468672360"; //9671203166

export var ADSENSE_BANNER_BOTTOM = "9671203166"; //2419399107

export var ADSENSE_VERTICAL_1 = "2419399107";
export var ENABLE_PAYPAL_DEBUG = true;
export var EMAIL_DEBUG = true;
export var THEME = "cosmobox";
export var SITE_NAME = "Cosmobox";
export var TITLE_SEPARATOR = " - ";
export var FACEBOOK_PAGE_LINK = "https://facebook.com/cosmoboxit";
export var INSTAGRAM_PAGE_LINK = "https://instagram.com/cosmoboxit";
export var TWITTER_PAGE_LINK = "https://twitter.com/cosmoboxit";
export var NO_PROFILE_PIC = "/res/images/no-profile-photo.jpg";
export var DB_PHOTOS_SEPERATOR = ",";
export var TEXT_BREAK_POINT = "::B::";
export var CURRENCY_CODE = "EUR";
export var PASSWORD_RESET_TOKEN_VALIDITY_PERIOD_SECS = 60 * 30; //30 munites

export var URL_ALLOWED_CHARS = ["_", ".", "-", "~"];
export var IMAGE_FILENAME_AND_DIMENSIONS_SEPERATOR = "-";
export var IMAGE_DIMENSIONS_SEPERATOR = "X";
export var MAX_RATING_STARS = 5;
export var PROFILE_PHOTO_SIZE_XS = 15;
export var PROFILE_PHOTO_SIZE_SM = 40;
export var PROFILE_PHOTO_SIZE_MD = 100;
export var PROFILE_PHOTO_SIZE_LG = 120;
export var DEFAULT_PHONE_COUNTRY = 'it';
export var MAX_REVIEW_PREVIEW_CHARS = 120;
export var REVIEWS_PER_PAGE = 15;
export var PRODUCTS_PER_PAGE = 12; //2

export var RELATED_PRODUCTS_LIMIT = 8; //2

export var PAGINATION_RANGE = 5;
export var CHATS_PER_PAGE = 50;
export var MESSAGES_PER_PAGE = 50;
export var FOLLOWS_PER_PAGE = 12;
export var USERLINK_PREFIX = "@";
export var NO_USERLINK_ID_PREFIX = "vendor-";
export var MSG_EDITOR_MAX_LENGTH = 500;
export var GENERAL_MESSAGE_CHAT_ID = "general";
export var CHAT_ID_SEPARATOR = "-";
export var DEFAULT_IMAGE_HOST = "https://static.cosmobox.it"; //"https://dev.domain.com:3000"//

export var PAYPAL_CLIENT_ID = function PAYPAL_CLIENT_ID() {
  return true ? process.env.NEXT_PUBLIC_PAYPAL_LIVE_CLIENT_ID : process.env.NEXT_PUBLIC_PAYPAL_SANDBOX_CLIENT_ID;
};
export var LISTING_BOOSTERS = {
  "0-days-boost": {
    days: 0,
    price: 0
  },
  "7-days-boost": {
    days: 7,
    price: 4.99
  },
  "30-days-boost": {
    days: 30,
    price: 19.99
  }
};
export var RANKS = {
  moderator: 1,
  admin: 2,
  owner: 3,
  engineer: 4
};
export var RANKS_PERMISSIONS = {
  verify_badge_awarding: [RANKS.admin, RANKS.owner, RANKS.engineer],
  general_messaging: [RANKS.admin, RANKS.owner, RANKS.engineer],
  daily_deals_marking: [RANKS.admin, RANKS.owner, RANKS.engineer],
  listing_moderation: [RANKS.moderator, RANKS.admin, RANKS.owner, RANKS.engineer]
};
/**
 * The length must be between 3 and 30 characters.
The accepted characters are like you said: a-z A-Z 0-9 dot(.) underline(_).
It's not allowed to have two or more consecutive dots in a row.
It's not allowed to start or end the username with a dot.
 */

export var USERNAME_VALIDITY_REGEX = /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/;
export var VERIFICATION_REQUESTS_KEYS = {
  passwordReset: "password_reset:",
  emailVerification: "email_verification:"
};
export var PASSWORD_VALIDITY_TYPES_USED = ['minLength8', 'noCharRepeat3', 'maxLength16'];
export var PASSWORD_VALIDITY_TYPES = {
  minLength6: {
    reg: /^.{6,}$/,
    invalidBool: false,
    error: "common:PASS_ERROR_MIN_LEN_6"
  },
  minLength8: {
    reg: /^.{8,}$/,
    invalidBool: false,
    error: "common:PASS_ERROR_MIN_LEN_8"
  },
  minLength16: {
    reg: /^.{16,}$/,
    invalidBool: false,
    error: "common:PASS_ERROR_MIN_LEN_16"
  },
  maxLength16: {
    reg: /^.{16,}$/,
    invalidBool: true,
    error: "common:PASS_ERROR_MAX_LEN_16"
  },
  maxLength32: {
    reg: /^.{32,}$/,
    invalidBool: true,
    error: "common:PASS_ERROR_MAX_LEN_32"
  },
  noCharRepeat3: {
    reg: /(.)\1{2,}/,
    invalidBool: true,
    error: "common:PASS_ERROR_REPEAT_3"
  },
  noCharRepeat5: {
    reg: /(.)\1{4,}/,
    invalidBool: true,
    error: "common:PASS_ERROR_REPEAT_5"
  },
  noCharRepeat8: {
    reg: /(.)\1{7,}/,
    invalidBool: true,
    error: "common:PASS_ERROR_REPEAT_8"
  },
  alphaNumeric: {
    reg: /^[^\p{L}\p{Nd}]+$/,
    invalidBool: true,
    error: "common:PASS_ERROR_A_Z_NUM"
  },
  alphaNumericBothCase: {
    reg: /^[^\p{Ll}\p{Lu}\p{N}]+$/,
    invalidBool: true,
    error: "common:PASS_ERROR_A_Z_NUM_BOTH_CASE"
  },
  specialChars: {
    reg: /[!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]+/,
    invalidBool: false,
    error: "common:PASS_ERROR_SPECIAL_CHARS"
  }
};
export var LOGS_PATH = {
  paypalPayment: {
    prod: "logs/paypal-payment.log",
    dev: "logs/paypal-payment-dev.log"
  },
  email: {
    prod: "logs/email.log",
    dev: "logs/email-dev.log"
  }
};
export var UPLOAD_MODERATION_METHODS = {
  none: 0,
  reviewBeforePublicity: 1,
  userReportAfterPublicity: 2
};
export var UPLOAD_MODERATIONS = [UPLOAD_MODERATION_METHODS.reviewBeforePublicity];